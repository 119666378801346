import { BaseProxy } from './BaseProxy'

export class GuestProxy extends BaseProxy {
  constructor ({ parameters = {} } = {}) {
    super({
      endpoint: 'guest',
      parameters,
    })
  }
}
