import en from './en.json'
import es from './es.json'
import fr from './fr.json'
import jp from './jp.json'
import ko from './ko.json'
import pt from './pt.json'
import ru from './ru.json'
import tr from './tr.json'
import it from './it.json'
import de from './de.json'
import languages from './languages.json'

export default {
  languages,
  en,
  es,
  fr,
  jp,
  ko,
  pt,
  ru,
  tr,
  it,
  de,
}
