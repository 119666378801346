<template>
  <div id="app" v-loading="loading">
    <div class="page-view">
      <div class="header is-flex vertical-align" data-aos="fade-down" data-aos-duration="1000">
        <div class="container">
          <div class="columns vertical-align is-mobile" data-aos="fade" data-aos-duration="1000" data-aos-delay="500">
            <div class="column is-narrow-mobile">
              <img src="./assets/images/meditopia-logo.svg" alt="" class="logo">
            </div>
            <div class="column text-right">
              <button class="register">
                <a :href="'http://offers.meditopia.com/subscribe/?lang=' + 'tr' + '&affcode=playlist'" target="_blank">{{$t('general.start_now')}}</a>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="container" v-if="playListData">
        <div class="columns">
          <div class="column is-12">
            <div class="image-box" data-aos="fade" data-aos-duration="1000" data-aos-delay="750">
              <img v-if="playListData.header" :src="playListData.header.backgroundImage" style="width:100%;" alt="">
              <div class="image-box__content" data-aos="fade" data-aos-duration="1000" data-aos-delay="1000">
                <p class="picklist">{{ playListData.header.title }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="columns" data-aos="fade" data-aos-duration="1000" data-aos-delay="1200">
          <div class="column">
            <div class="influencer-content">
              <p>
                {{ playListData.items[0].expandableText.previewText }}
              </p>
              <p style="margin-top:15px;" v-if="openExpandableText">
                {{ playListData.items[0].expandableText.text }}
              </p>
              <p @click="openExpandableText = !openExpandableText" class="read-more">
                <span v-if="!openExpandableText">{{$t('general.read_more')}} <img src="./assets/images/arrow.svg" alt=""></span>
                <span v-else>{{$t('general.view_less')}} <img style="transform: rotate(180deg)" src="./assets/images/arrow.svg" alt=""></span>
              </p>
            </div>
          </div>
        </div>
        <div class="columns" data-aos="fade" data-aos-duration="1000">
          <div class="column">
            <div class="page-banner">
              <div class="columns vertical-align">
                <div class="column">
                  <p>{{$t('general.try_free')}}</p>
                  <span class="hide-mobile">{{$t('general.unlimited_access')}}</span>
                </div>
                <div class="column is-narrow text-center">
                  <button class="start-now" style="margin-top:10px;">
                    <a :href="'http://offers.meditopia.com/subscribe/?lang=' + lang + '&affcode=playlist'" target="_blank">{{$t('general.start_now')}}</a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a class="play-link" data-aos="fade" data-aos-duration="1000" :href="'https://app.adjust.com/95asfml?deeplink=meditopia%3A%2F%2Fplaylist%3Fid%3D' + playListData.playlistID" target="_blank" :key=key v-for="(data,key) in playListData.items">
          <div class="columns is-multiline is-mobile playlist" v-if="data.type === '2' || data.type === '4'">
            <div class="column is-12">
              <div class="columns is-multiline">
                <div v-if="data.type === '2'" class="column is-12">
                  <p class="playlist__title">{{data.text}}</p>
                </div>
                <div class="column is-12 playlist__song" v-else-if="data.type === '4'">
                  <div class="playlist__song__inner">
                    <div class="columns is-mobile vertical-align">
                      <div class="column is-narrow is-narrow-mobile">
                        <img class="cover-img" :src="data.content.image" alt="">
                      </div>
                      <div class="column">
                        <p>{{data.content.title}}</p>
                        <span>{{data.content.subtitle}}</span>
                      </div>
                      <div class="column is-narrow is-narrow-mobile text-right">
                        <img src="./assets/images/clock.svg" class="icon" alt="">
                        <span>{{setDuration(data.content.duration)}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
        <div class="mobile-fixed-bottom">
          <button>
            <a :href="'meditopia://playlist?id=' + playListData.playlistID" target="_blank">{{$t('general.open_in_app')}}</a>
          </button>
        </div>
      </div>
      <div v-if="showNoData" data-aos="fade" data-aos-duration="1000" data-aos-delay="750">
        <div class="no-data">
          <img src="./assets/images/cloud.svg" alt="">
          <p>{{$t('general.no_data')}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PlaylistProxy } from './proxies/PlaylistProxy'
import { GuestProxy } from './proxies/GuestProxy'

export default {
  name: 'App',
  data () {
    return {
      playListData: null,
      openExpandableText: false,
      sortedData: [],
      loading: false,
      showNoData: false,
      lang: null,
    }
  },
  metaInfo() {
    return {
      title: this.playListData ? this.playListData.header.title : 'Meditopia',
      meta: [
        { name: 'description', content: this.playListData ? this.playListData.items[0].expandableText.previewText : ''},
        { property: 'og:title', content: this.playListData ? this.playListData.header.title : 'Meditopia'},
        { property: 'og:description', content: this.playListData ? this.playListData.items[0].expandableText.previewText : ''},
        {property: 'og:url', content: 'https://meditopia.com/[lang]/playlist/[playlistid]'},
        {property: 'og:image', content: this.playListData ? this.playListData.header.backgroundImage : '' }
      ]
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData () {
      this.loading = true
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      if (queryString !== "") {
        this.lang = urlParams.get('lang')
        const playlistID = urlParams.get('playlistid')
        const guestData = new FormData()
        guestData.append('udid', Math.random().toString(36).substr(2, 9))
        guestData.append('language', this.lang)
        new GuestProxy()
            .create({ item: guestData })
            .then(response => {
              const playListFormData = new FormData()
              playListFormData.append('user_id', response.data.userid)
              playListFormData.append('lang', this.lang)
              playListFormData.append('playlist_id', playlistID)
              new PlaylistProxy()
                  .create({ item: playListFormData })
                  .then(response => {
                    this.playListData = response.data
                  }).catch(() => {
                this.showNoData = true
                console.log('Playlist Error')
              })
            })
        this.loading = false
      } else {
        this.loading = false
        this.showNoData = true
      }

    },
    setDuration (duration) {
      let durmin = Math.floor(duration / 60)
      let dursec = Math.floor(duration - durmin * 60)
      return dursec + "'" + durmin + "''"
    },
  },
}
</script>
