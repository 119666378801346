import {
  Meditopia,
} from '@/utils/request'

import qs from 'qs'

const datableTypes = ['post', 'put']

const ApiMap = new Map()
ApiMap.set('meditopia', Meditopia)

export class BaseProxy {
  constructor ({ api = 'meditopia', endpoint, parameters = { headers: {} } }) {
    this.$http = ApiMap.get(api) || Meditopia
    this.endpoint = endpoint
    this.parameters = parameters
    this.config = {
      headers: {},
    }
  }

  setHeader (key, value) {
    this.config.headers = { ...this.config.headers, [key]: value }
    return this
  }

  setParameter (parameter, value) {
    this.parameters[parameter] = value
    return this
  }

  submit (requestType, url, data = null) {
    return new Promise((resolve, reject) => {
      const args = [url + this.getParameterString(), this.config]
      if (datableTypes.includes(requestType)) {
        args.splice(1, 0, data)
      }
      this.$http[requestType](...args)
        .then((response) => {
          resolve(response.data)
        })
        .catch(({ response }) => {
          if (response) {
            reject(response.data)
          } else {
            reject(response)
          }
        })
    })
  }

  all () {
    return this.submit('get', `/${this.endpoint}`)
  }

  create ({ item }) {
    return this.submit('post', `/${this.endpoint}`, item)
  }

  getParameterString () {
    const parameterStrings = qs.stringify(this.parameters, { encodeValuesOnly: true })

    return parameterStrings.length === 0 ? '' : `?${parameterStrings}`
  }
}
