import Axios from 'axios'

const Meditopia = Axios.create({
  baseURL: process.env.VUE_APP_BASEAPI,
})


const requestInterceptors = [
  [
    function (config) {
      config.headers.common.Authorization = 'Basic bWVkaXRhc3lvbjoxMDk4'
      return config
    },
    function (error) {
      return Promise.reject(error)
    },
  ],
]

requestInterceptors.forEach(interceptor => {
  Meditopia.interceptors.request.use(...interceptor)
})

export {
  Meditopia,
}
