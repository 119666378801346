import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/style/app.scss'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { i18n } from './plugins/vue-i18n'
import './plugins/element'

import VueMeta from 'vue-meta'

Vue.use(VueMeta)

import VueAnalytics from 'vue-analytics'

Vue.use(VueAnalytics, {
  id: 'UA-63509240-6'
})

import VueFacebookPixel from 'vue-analytics-fb'

Vue.use(VueFacebookPixel)
Vue.analytics.fbq.init('258344494853715')
window.fbAsyncInit = function () {
  Vue.analytics.fbq.init({
    appId: '258344494853715',
    xfbml: true,
    version: 'v2.8'
  })
  Vue.analytics.fbq.AppEvents.logPageView()
}
Vue.config.productionTip = false

new Vue({
  created () {
    AOS.init()
  },
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
