import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '../locales'

Vue.use(VueI18n)

/**
 * To set lang for i18n locale parameters getting search parameter from url
 */
const queryString = window.location.search
const urlParams = new URLSearchParams(queryString);
const lang = urlParams.get('lang');

export const i18n = new VueI18n({
  locale: lang || process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages,
})
